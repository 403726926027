import URL_ROUTE from '../constants/index';

const token = sessionStorage.getItem('token');

class Api {
    async login(email, pass, ip) {
        let data = {
            email,
            pass,
            ip
        };
        const query = await fetch(`${URL_ROUTE.LOGIN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchPatientAppoinments(npi) {
        let data = {
            npi
        };
        const query = await fetch(`${URL_ROUTE.SEARCH_PATIENTS_APPOINTMENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async uploadEvidences(idappointment, nameuser, upload) {
        let formData = new FormData();
        formData.append('idappointment', idappointment);
        formData.append('nameuser', nameuser);
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOAD_EVIDENCES}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async createAppointment(npi, name, lastname, datebirth, phone, email, address, assure, which, iduser, date, hour, nameuser, description, idtypeappointment, idinsurance) {
        let data = {
            npi,
            name,
            lastname,
            datebirth,
            phone,
            email,
            address,
            assure,
            which,
            iduser,
            date,
            hour,
            nameuser,
            description,
            idtypeappointment,
            idinsurance
        };
        const query = await fetch(`${URL_ROUTE.CREATE_APPOINTMENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async loadInsurance(idtop) {
        let data = {
            idtop
        };
        const query = await fetch(`${URL_ROUTE.LOAD_INSURANCE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async loadAppointments(idstatus) {
        let data = {
            idstatus
        };
        const query = await fetch(`${URL_ROUTE.LIST_APPOINTMENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async changeStatusAppointment(id, idstatus, nameuser) {
        let data = {
            id,
            idstatus,
            nameuser
        };
        const query = await fetch(`${URL_ROUTE.CHANGE_STATUS_APPOINTMENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listFileAppointments(idappointment) {
        let data = {
            idappointment
        };
        const query = await fetch(`${URL_ROUTE.LIST_FILE_APPOINTMENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createReffered(phone, name, iduser) {
        let data = {
            phone,
            name,
            iduser
        };
        const query = await fetch(`${URL_ROUTE.CREATE_REFFERED}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listRefferedAll() {
        const query = await fetch(`${URL_ROUTE.LIST_REFFERED_ALL}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listReffered(iduser) {
        let data = {
            iduser
        };
        const query = await fetch(`${URL_ROUTE.LIST_REFFERED_AGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async changeStatusReffered(idstatus, id) {
        let data = {
            idstatus,
            id
        };
        const query = await fetch(`${URL_ROUTE.UPDATE_REFFERED}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAppStatusUser(idstatus, iduser) {
        let data = {
            idstatus,
            iduser
        };
        const query = await fetch(`${URL_ROUTE.LIST_APP_STATUS_USER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async countAppointmentTotalDayMonth(idstatus) {
        let data = {
            idstatus
        };
        const query = await fetch(`${URL_ROUTE.COUNT_APPOINTMENT_TOTAL_DAY_MONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createTracker(idappointment, description, idstatus, iduser, nameuser) {
        let data = {
            idappointment,
            description,
            idstatus,
            iduser,
            nameuser
        };
        const query = await fetch(`${URL_ROUTE.CREATE_TRACKER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listTracker(idappointment) {
        let data = {
            idappointment
        };
        const query = await fetch(`${URL_ROUTE.LIST_TRACKER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async editDataUser(id, name, lastname, phone, email, address, idappointment, date, hour, description, nameuser) {
        let data = {
            id,
            name,
            lastname,
            phone,
            email,
            address,
            idappointment,
            date,
            hour,
            description,
            nameuser
        };
        const query = await fetch(`${URL_ROUTE.EDIT_DATA_USER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async loadparameter(idtop) {
        let data = {
            idtop
        };
        const query = await fetch(`${URL_ROUTE.LOAD_PARAMETER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listEmailModule(nameModule) {
        let data = {
            nameModule
        };
        const query = await fetch(`${URL_ROUTE.LIST_EMAIL_MODULE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createformobamacare(npi, name, lastname, age, phone, email, address, zone, idstatusmigratorie, work, assuremedic, ssn, accountbank, dependients, incometax, iduser, nameuser, date, hour, description, code) {
        console.log(nameuser);
        let data = {
            npi,
            name,
            lastname,
            age,
            phone,
            email,
            address,
            zone,
            idstatusmigratorie,
            work,
            assuremedic,
            ssn,
            accountbank,
            dependients,
            incometax,
            iduser,
            nameuser,
            date,
            hour,
            description,
            code
        };
        const query = await fetch(`${URL_ROUTE.CREATE_FORM_OBAMA_CARE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return query;
    }

    async liststatus(idstatustracker) {
        let data = {
            idstatustracker
        };
        const query = await fetch(`${URL_ROUTE.LIST_STATUS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async liststatussupervisor(idstatustracker) {
        let data = {
            idstatustracker
        };
        const query = await fetch(`${URL_ROUTE.LIST_STATUS_SUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchpatients(npi) {
        let data = {
            npi
        };
        const query = await fetch(`${URL_ROUTE.SEARCH_PATIENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listtracking(idobamacare) {
        let data = {
            idobamacare
        };
        const query = await fetch(`${URL_ROUTE.LIST_TRACKING}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createtracking(idobamacare, description, idstatus, iduser, nameuser, idagent) {
        let data = {
            idobamacare,
            description,
            idstatus,
            iduser,
            nameuser,
            idagent
        };
        const query = await fetch(`${URL_ROUTE.CREATE_TRACKING}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatetracking(id, description, idagent) {
        let data = {
            id,
            description,
            idagent
        };
        const query = await fetch(`${URL_ROUTE.UPDATE_TRACKING}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    /* upload */

    async uploadexcelfiles(agent, upload) {
        let formData = new FormData();
        formData.append('agent', agent);
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOADEXCELFILE}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async uploadexcelAgentsBetween(upload) {
        let formData = new FormData();
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOADEXCELAGENTSBETWEEN}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async uploadPay(company, upload) {
        let formData = new FormData();
        formData.append('company', company);
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOADPAY}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async uploadNumberRenew(id_user, name_user, upload) {
        let formData = new FormData();
        formData.append('id_user', id_user);
        formData.append('name_user', name_user);
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOADNUMBERRENEW}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async lsitphoneagentall(day) {
        let data = {
            day
        }
        const query = await fetch(`${URL_ROUTE.LISTPHONEAGENTALL}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async lsitphoneagentallSupervisor(day, user_agents) {
        let data = {
            day, user_agents
        }
        const query = await fetch(`${URL_ROUTE.LISTPHONEAGENTALLSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAllUser(idagent, day) {
        let data = { idagent }
        const query = await fetch(`${URL_ROUTE.LISTALLUSER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentAll(idagent) {
        let data = { idagent };
        const query = await fetch(`${URL_ROUTE.LISTAGENTALL}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatestatus(id, observation, status) {
        let data = { id, observation, status };
        const query = await fetch(`${URL_ROUTE.UPDATESTATUS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentAllFilter(idagent, idstatus, day) {
        let data = { idagent, idstatus }
        const query = await fetch(`${URL_ROUTE.LISTAGENTALLFILTER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentFilter(idagent, day) {
        let data = { idagent }
        const query = await fetch(`${URL_ROUTE.LISTAGENTFILTER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentAllFilterStatus(idstatus, idagent, day) {
        let data = { idstatus, idagent }
        const query = await fetch(`${URL_ROUTE.LISTAGENTFILTERSTATUS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    // USERS
    async createuser(email, name, lastname, password, idprofile, checkagency) {
        let data = { email, name, lastname, password, idprofile, checkagency }
        const query = await fetch(`${URL_ROUTE.CREATEUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listusers() {
        const query = await fetch(`${URL_ROUTE.LISTUSERS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listusersAll() {
        const query = await fetch(`${URL_ROUTE.LISTUSERSALL}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async AddAgents(id, agents) {
        let data = { id, agents }
        const query = await fetch(`${URL_ROUTE.ADDAGENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async AddNPN(id, agents) {
        let data = { id, agents }
        const query = await fetch(`${URL_ROUTE.ADDNPN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchAgents(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHAGENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchNPN(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHNPN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listNPN(id) {
        const query = await fetch(`${URL_ROUTE.LISTNPN}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async updateusers(id, name, lastname, idprofile) {
        let data = { id, name, lastname, idprofile }
        const query = await fetch(`${URL_ROUTE.UPDATEUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateStatus(id, estado) {
        let data = { id, estado }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatePass(id, password) {
        let data = { id, password }
        const query = await fetch(`${URL_ROUTE.UPDATEPASSUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listagent() {
        const query = await fetch(`${URL_ROUTE.LISTAGENT}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listagentON() {
        const query = await fetch(`${URL_ROUTE.LISTAGENTON}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listAgentONSupervisor(user_agents) {
        let data = { user_agents }
        const query = await fetch(`${URL_ROUTE.LISTAGENTONSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentProfile(idprofile) {
        let data = { idprofile }
        const query = await fetch(`${URL_ROUTE.LISTAGENTPROFILE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async reportLogin(dateStart, dateEnd, iduser) {
        let data = {
            dateStart, dateEnd, iduser
        }
        const query = await fetch(`${URL_ROUTE.REPORTLOGIN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    // AGENT
    async createassignedagent(name, email, phone, password) {
        let data = { name, email, phone, password }
        const query = await fetch(`${URL_ROUTE.CREATEASSIGNEDAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateassignedagent(id, name, email, phone) {
        let data = { id, name, email, phone }
        const query = await fetch(`${URL_ROUTE.UPDATEASSIGNEDAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatestatusagent(id, status) {
        let data = { id, status }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listagentassigned() {
        const query = await fetch(`${URL_ROUTE.LISTASSIGNEDAGENT}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async updateformobamacare(id, idpatient, npi, name, lastname, age, phone, email, address, zone, idstatusmigratorie, work, assuremedic, ssn, accountbank, dependients, incometax, nameuser, date, hour, description, code, uploadAudio) {
        let data = new FormData();
        data.append('id', id);
        data.append('idpatient', idpatient);
        data.append('npi', npi);
        data.append('name', name);
        data.append('lastname', lastname);
        data.append('age', age);
        data.append('phone', phone);
        data.append('email', email);
        data.append('address', address);
        data.append('zone', zone);
        data.append('idstatusmigratorie', idstatusmigratorie);
        data.append('work', work);
        data.append('assuremedic', assuremedic);
        data.append('ssn', ssn);
        data.append('accountbank', accountbank);
        data.append('dependients', dependients);
        data.append('incometax', incometax);
        data.append('nameuser', nameuser);
        data.append('date', date);
        data.append('hour', hour);
        data.append('description', description);
        data.append('code', code);
        data.append('uploadAudio', uploadAudio);

        const query = await fetch(`${URL_ROUTE.UPDATE_FORMOBAMACARE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data
        });
        return query
    }

    // CREATE LIST PHONE CARE CROSS
    async createlistphonecarecross(datecreate, iduser, nameuser, phone) {
        let data = { datecreate, iduser, nameuser, phone }
        const query = await fetch(`${URL_ROUTE.CREATELISTCARECROSS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listphonecarecross() {
        const query = await fetch(`${URL_ROUTE.LISTPHONECARECROSS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async reportstatusphone(idstatus) {
        let data = {
            idstatus
        }
        const query = await fetch(`${URL_ROUTE.REPORTSTATUSPHONE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async reportnumbernoansweragent(idstatus, idstatus2, user_id) {
        let data = {
            idstatus, idstatus2, user_id
        }
        const query = await fetch(`${URL_ROUTE.REPORTSTATUSPHONENOANSWERAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deleteappointment(idappointment) {
        let data = {
            idappointment
        }
        const query = await fetch(`${URL_ROUTE.DELETEAPPOINTMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatestatusphoneagent(id, id_agent, id_status) {
        let data = {
            id, id_agent, id_status
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSPHONEAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    /* ------------------------------------------------------ */

    async createsales(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        const query = await fetch(`${URL_ROUTE.CREATESALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesDataRenew(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, renew, id_sales_renew, county) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('renew', renew)
        data.append('id_sales_renew', id_sales_renew)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATARENEW}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async createsalesPending(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, observation2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, county) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('observation2', observation2)
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.CREATESALESPENDING}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async createsalesDraftAgent(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, observation2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, county) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('observation2', observation2)
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.CREATESALESDRAFTAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesales(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, locationUrl, county) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('locationUrl', locationUrl)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesQuality(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, county) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALESQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesProcess(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, project_in = null, county) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('project_in', project_in)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALESPROCESS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesAgent(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, project_in = null, location, county) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('project_in', project_in)
        data.append('location', location)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALESAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesData(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, county) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATA}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesDataOther(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, id_status, project_in, county) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('id_status', id_status)
        data.append('project_in', project_in)
        data.append('county', county)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATAOTHER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesDataRecovery(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, observation_immigration, observation_immigration2, callOrigin, user_name, recovery, user_id, genderApplicant, genderApplicant2) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_salesman', user_id)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('recovery', recovery)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATARECOVERY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updateSalesFile(id_sales, idfiles, uploadfiles, iduser, nameuser, observationFile) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('idfiles', idfiles)
        data.append('iduser', iduser)
        data.append('nameuser', nameuser)
        data.append('observation', observationFile != '' && observationFile != null && observationFile != undefined  ? observationFile : '-')
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        const query = await fetch(`${URL_ROUTE.UPDATESALESFILE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updateSalesVerifyBackOffice(id_sales) {
        let data = {
            id_sales
        }
        const query = await fetch(`${URL_ROUTE.UPDATESALESVERIFYBACKOFFICE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateUploadSalesDocument(arrayFiles) {
        let data = {
            arrayFiles
        }
        const query = await fetch(`${URL_ROUTE.UPDATEUPLOADSALESDOCUMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusFinish(idsales, idstatus_finish, iduser, nameuser) {
        let data = {
            id: idsales, idstatus_finish, iduser, nameuser
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSFINISH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusFinishNPN(idsales, idstatus_finish, npn, iduser, nameuser) {
        let data = {
            id: idsales, idstatus_finish, npn, iduser, nameuser
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSFINISHNPN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusFinishNPNCancelled(idsales, idstatus_finish, npn, iduser, nameuser, name_cancelled, email_cancelled, phone_cancelled) {
        let data = {
            id: idsales, idstatus_finish, npn, iduser, nameuser, name_cancelled, email_cancelled, phone_cancelled
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSFINISHNPNCANCELLED}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async managementObservation(idsale, management, management_observation, username) {
        let data = {
            id: idsale, management, management_observation, username
        }
        const query = await fetch(`${URL_ROUTE.MANAGEMENTOBSERVATION}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createAudit(iduser, username, description, idsale, action) {
        let data = {
            iduser, username, description, idsale, action
        }
        const query = await fetch(`${URL_ROUTE.CREATEAUDIT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateNPN(idsales, npn) {
        let data = {
            id: idsales, npn
        }
        const query = await fetch(`${URL_ROUTE.UPDATENPN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateSherpa(idsales, idsherpa) {
        let data = {
            id: idsales, idsherpa
        }
        const query = await fetch(`${URL_ROUTE.UPDATESHERPA}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalespre(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalespreConsultor(id, user_agents) {
        let data = {
            id, user_agents
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRECONSULTOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalespreNew(id, transfer) {
        let data = {
            id, transfer
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRENEW}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listSalesSupervisorPrenew(id_status, user_agents, location) {
        let data = { id_status, user_agents, location }
        const query = await fetch(`${URL_ROUTE.LISTSALESSUPERVISORPRENEW}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalespreNewRecovery(id, transfer) {
        let data = {
            id, transfer
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRENEWRECOVERY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprenewTransferUserID(id, transfer, user_id) {
        let data = {
            id, transfer, user_id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRENEWTRANSFERUSERID}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalespreNewVerify(id, transfer, verify, validateReNew) {
        let data = {
            id, transfer, verify, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRENEWVERIFY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listSalesAdminAgents(id, transfer, verify, validateReNew, salesman) {
        let data = {
            id, transfer, verify, validateReNew, salesman
        }
        const query = await fetch(`${URL_ROUTE.LISTSALESADMINAGENTS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listSalesAdminAgentsStatusFinish(id_status, idstatus_finish, salesman) {
        let data = {
            id_status, idstatus_finish, salesman
        }
        const query = await fetch(`${URL_ROUTE.LISTSALESADMINAGENTSTATUSFINISH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesdepent(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESDEPENDENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesplan(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPLAN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesbeneficiaries(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESBENEFICIARIES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchuploadsalesdocument(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHUPLOADSALESDOCUMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async deleteuploadsales(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETEUPLOADSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesagents(id, id_user) {
        let data = {
            id, id_user
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESAGENTS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesagentsRenovation(id, id_user) {
        let data = {
            id, id_user
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESAGENTSRENOVATION}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessor(id, id_agency, validateReNew) {
        let data = {
            id, id_agency, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessorNoSS(id, id_agency, validateReNew) {
        let data = {
            id, id_agency, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSORNOSS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessorSS(id, id_agency, validateReNew) {
        let data = {
            id, id_agency, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSORSS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesCustomerMonth(id, id_agency, user_id, user_agents) {
        let data = {
            id, id_agency, user_id, user_agents
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESCUSTOMERMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessorBO(id, id_agency, validateReNew) {
        let data = {
            id, id_agency, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSORBO}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessorRecovery(id, id_agency) {
        let data = {
            id, id_agency
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSORRECOVERY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesobservation(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESOBSERVATION}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async exportFileXLXS(filename, tableOrAll, typeSale, filterdate, filterEndDate, filterphone, filterfullname, stateFilter, typeCallFilter) {
        let data = {
            filename, tableOrAll, typeSale, filterdate: filterdate !== "" ? filterdate : '-',
            filterEndDate: filterEndDate !== "" ? filterEndDate : '-',
            filterphone: filterphone !== "" ? filterphone : '-',
            filterfullname: filterfullname !== "" ? filterfullname : '-',
            stateFilter: stateFilter !== "" ? stateFilter : '-',
            typeCallFilter: typeCallFilter !== "" ? typeCallFilter : '-'
        }
        const query = await fetch(`${URL_ROUTE.EXPORTFILESXLXS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async exportFileXLXSProcessor(filename, typeSale, filterdate, filterEndDate, filterphone, filterfullname, stateFilter, typeCallFilter) {
        let data = {
            filename, typeSale, filterdate: filterdate !== "" ? filterdate : '-',
            filterEndDate: filterEndDate !== "" ? filterEndDate : '-',
            filterphone: filterphone !== "" ? filterphone : '-',
            filterfullname: filterfullname !== "" ? filterfullname : '-',
            stateFilter: stateFilter !== "" ? stateFilter : '-',
            typeCallFilter: typeCallFilter !== "" ? typeCallFilter : '-'
        }
        const query = await fetch(`${URL_ROUTE.EXPORTFILESXLXSPROCESSOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async exportFileXLXSQuality(filename, typeSale, filterdate, filterEndDate, stateFilter) {
        let data = {
            filename, typeSale, filterdate: filterdate !== "" ? filterdate : '-',
            filterEndDate: filterEndDate !== "" ? filterEndDate : '-',
            stateFilter: stateFilter !== "" ? stateFilter : '-',
        }
        const query = await fetch(`${URL_ROUTE.EXPORTFILESXLXSQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deleteFileXLXS(filename) {
        let data = { filename }
        const query = await fetch(`${URL_ROUTE.DELETEFILESXLXS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    /* ---------------------------------------------- */

    async listprenewappointmentsales(id_status) {
        let data = {
            id_status
        }
        const query = await fetch(`${URL_ROUTE.LISTPRENEWAPPOINTMENTSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listappointmentsalesdate(date1, date2, id_status) {
        let data = {
            date1, date2, id_status
        }
        const query = await fetch(`${URL_ROUTE.LISTAPPOINTMENTSALESDATE}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchappointmentsales(id_sales) {
        let data = {
            id_sales
        }
        const query = await fetch(`${URL_ROUTE.SEARCHAPPOINTMENTSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchsales(id_status) {
        let data = {
            id_status
        }
        const query = await fetch(`${URL_ROUTE.SEACRHSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateformsales(id, id_patient, name, lastname, email, phone, datebirth, age, migratory, work, insurance, code, dependents, tax, ss, direction, phoneConyuge, alien, card, cat, expDate, folio, dataRow, principalIncome,
        incomeConyge, totalIncome, employer, phoneEmployer, w2, bank, accountNumber, routingNumber, automaticPayment, payDay, debit, credit, cardNumber, expDateCard, cvcCode, insurer, premium, npnUsed, referral, marketplace,
        deadline, typePlan, deductible, max, nameNpn, supplementalBenefit, policyNumber, beneficiary, type_bank, detail, observation, agency) {
        let data = {
            id, id_patient, name, lastname, email, phone, datebirth, age, migratory, work, insurance, code, dependents, tax, ss, direction, phoneConyuge, alien, card, cat, expDate, folio, dataRow, principalIncome,
            incomeConyge, totalIncome, employer, phoneEmployer, w2, bank, accountNumber, routingNumber, automaticPayment, payDay, debit, credit, cardNumber, expDateCard, cvcCode, insurer, premium, npnUsed,
            referral, marketplace, deadline, typePlan, deductible, max, nameNpn, supplementalBenefit, policyNumber, beneficiary, type_bank, detail, observation, agency
        }
        const query = await fetch(`${URL_ROUTE.UPDATEFORMSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deletesales(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETESALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async uploadformsuplemental(name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation, user_id, nameuser) {
        let data = {
            name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation, user_id, nameuser
        }
        const query = await fetch(`${URL_ROUTE.UPLOADFORMSUPLEMENTAL}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listformsuplemental(idstatus) {
        let data = {
            idstatus
        }
        const query = await fetch(`${URL_ROUTE.LISTFORMSUPLEMENTAL}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateformsuplemental(id_suplemental, id_patient, name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation/* , agency */) {
        let data = {
            id_suplemental, id_patient, name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation/* , agency */
        }
        const query = await fetch(`${URL_ROUTE.UPDATEFORMSUPLEMENTAL}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deletesuplemental(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETESUPLEMENTAL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    /* AGENCY */
    async listagency() {
        const query = await fetch(`${URL_ROUTE.LISTAGENCY}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async createagency(nameagency, email, id_user) {
        let data = {
            nameagency,
            email,
            id_user
        }
        const query = await fetch(`${URL_ROUTE.CREATEAGENCY}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateagency(id, nameagency, email) {
        let data = {
            id,
            nameagency,
            email
        }
        const query = await fetch(`${URL_ROUTE.UPDATEAGENCY}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusSales(id, idstatus, observation, user_id, nameuser) {
        let data = { id, idstatus, observation, user_id, nameuser }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createLogCMSSales(id, url_cms, nameuser, email_receives) {
        let data = { id, url_cms, nameuser, email_receives }
        const query = await fetch(`${URL_ROUTE.CREATELOGCMSSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async deleteSignatureCMS(idsale, id, nameuser, description, password) {
        let data = { id, idsale, description, nameuser, password }
        const query = await fetch(`${URL_ROUTE.DELETESIGNATURECMS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSales(policytype, npn) {
        let data = { policytype, npn }
        const query = await fetch(`${URL_ROUTE.TOTALSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async totalSalesActive(policytype, npn) {
        let data = { policytype, npn }
        const query = await fetch(`${URL_ROUTE.TOTALSALESACTIVE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async totalSalesActiveAgent(user_id) {
        let data = { user_id }
        const query = await fetch(`${URL_ROUTE.TOTALSALESACTIVEAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async totalActiveMembers(npn) {
        let data = {npn}
        const query = await fetch(`${URL_ROUTE.TOTALACTIVEMEMBERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async totalSalesOtherParty(npn) {
        let data = {npn}
        const query = await fetch(`${URL_ROUTE.TOTALSALESOTHERPARTY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async totalSalesNPN(policytype, npn) {
        let data = { policytype, npn }
        const query = await fetch(`${URL_ROUTE.TOTALSALESNPN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesConsultor(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCONSULTOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesConsultorStatusFinish(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCONSULTORSTATUSFINISH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesSupervisor(policytype, user_agents) {
        let data = { policytype, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesRenew(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESRENEW}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesRenewSupervisor(policytype, user_agents) {
        let data = { policytype, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESRENEWSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesTransfer(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESTRANSFER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesLastDays(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESLASTDAYS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesLastDaysSupervisor(policytype, user_agents) {
        let data = { policytype, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESLASTDAYSSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgents(date, policytype, filterday) {
        let data = { date, policytype, filterday }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsSupervisor(date, policytype, user_agents) {
        let data = { date, policytype, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsPlanDay(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSPLANDAY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsPlanDaySupervisor(date, salesman, user_agents) {
        let data = { date, salesman, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSPLANDAYSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsPlanMonth(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSPLANMONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsPlanMonthSupervisor(date, salesman, user_agents) {
        let data = { date, salesman, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSPLANMONTHSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyDay(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYDAY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyMonth(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYMONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyYear(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYYEAR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyDaySupervisor(date, salesman, user_agents) {
        let data = { date, salesman, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYDAYSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyMonthSupervisor(date, salesman, user_agents) {
        let data = { date, salesman, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYMONTHSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyYearSupervisor(date, salesman, user_agents) {
        let data = { date, salesman, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYYEARSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonth(date, policytype) {
        let data = { date, policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthSupervisor(date, policytype, user_agents) {
        let data = { date, policytype, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthNPN(date, policytype, npn) {
        let data = { date, policytype, npn }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHNPN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthUser(user_id, date, policytype) {
        const data = {
            user_id, date, policytype
        }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHUSER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsUser(user_id, date, policytype) {
        const data = {
            user_id, date, policytype
        }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSUSER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthLastVsCurrent(date, policytype) {
        const data = { date, policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHLASTVSCURRENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthLastVsCurrentSupervisor(date, policytype, user_agents) {
        const data = { date, policytype, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHLASTVSCURRENTSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesForPlan(date) {
        const data = { date }
        const query = await fetch(`${URL_ROUTE.TOTALSALESFORPLAN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesForPlanSupervisor(date, user_agents) {
        const data = { date, user_agents }
        const query = await fetch(`${URL_ROUTE.TOTALSALESFORPLANSUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listYears() {
        const query = await fetch(`${URL_ROUTE.LISTYEARS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async listGoals(year) {
        const data = {
            year
        }
        const query = await fetch(`${URL_ROUTE.LISTGOALS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createGoals(month) {
        const data = {
            month
        }
        const query = await fetch(`${URL_ROUTE.CREATEGOALS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async editGoals(id, goal, goalagent) {
        const data = {
            id, goal, goalagent
        }
        const query = await fetch(`${URL_ROUTE.EDITGOALS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createGoalPremiumYear(year, goal, uploadfiles) {
        let data = new FormData();
        data.append('year', year)
        data.append('goal', goal)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        const query = await fetch(`${URL_ROUTE.CREATEGOALPREMIUMYEAR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data
        });
        return query
    }

    async searchNotaPad(user_id) {
        let data = {
            user_id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHNOTEPAD}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listNotePad(user_id) {
        let data = {
            user_id
        }
        const query = await fetch(`${URL_ROUTE.LISTNOTEPAD}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveNotaPad(id, user_id, text, title) {
        let data = {
            user_id, text: text != '' ? text : '-', id: id != '' ? String(id) : '-', title
        }
        const query = await fetch(`${URL_ROUTE.SAVENOTEPAD}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveNoteColumns(user_id, tableColumns) {
        let data = {
            user_id, tableColumns
        }
        const query = await fetch(`${URL_ROUTE.SAVENOTECOLUMNS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchNoteColumns(user_id) {
        let data = {
            user_id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHNOTECOLUMNS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchGoalPremiumYear(year) {
        let data = {
            year
        }
        const query = await fetch(`${URL_ROUTE.SEARCHGOALPREMIUMYEAR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listSalesSupervisor(id_status, user_agents) {
        let data = { id_status, user_agents }
        const query = await fetch(`${URL_ROUTE.LISTSALESSUPERVISOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listSalesNPN(id_status, npn) {
        let data = { id_status, npn }
        const query = await fetch(`${URL_ROUTE.LISTSALESNPN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listSalesBackOffice(id_status, validateReNew) {
        let data = { id_status, validateReNew }
        const query = await fetch(`${URL_ROUTE.LISTSALESBACKOFFICE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async verifyNumberPlan(phone, zipcode) {
        let data = { phone, zipcode }
        const query = await fetch(`${URL_ROUTE.VERIFYNUMBERPLAN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalesquality(id_status, idstatus_finish) {
        let data = { id_status, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalesManaged(id_status, idstatus_finish, managed) {
        let data = { id_status, idstatus_finish, managed }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESMANAGED}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalesqualityTransfer(id_status, idstatus_finish, transfer) {
        let data = { id_status, idstatus_finish, transfer }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESQUALITYTRANSFER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchVerifyMonth(year, idsale) {
        let data = { year, idsale }
        const query = await fetch(`${URL_ROUTE.SEARCHVERIFYMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async insertVerifyMonth(year, idsale, january, febrary, march, april, may, june, july, august, september, october, november, december, companyJanuary, companyFebrary, companyMarch, companyApril, companyMay, companyJune, companyJuly, companyAugust, companySeptember, companyOctober, companyNovember, companyDecember) {
        let data = { year, idsale,
            january: january != "" && january != undefined ? january : "-",
            febrary: febrary != "" && febrary != undefined ? febrary : "-",
            march: march != "" && march != undefined ? march : "-",
            april: april != "" && april != undefined ? april : "-",
            may: may != "" && may != undefined ? may : "-",
            june: june != "" && june != undefined ? june : "-",
            july: july != "" && july != undefined ? july : "-",
            august: august != "" && august != undefined ? august : "-",
            september: september != "" && september != undefined ? september : "-",
            october: october != "" && october != undefined ? october : "-",
            november: november != "" && november != undefined ? november : "-",
            december: december != "" && december != undefined ? december : "-",
            companyJanuary: companyJanuary != "" && companyJanuary != undefined ? companyJanuary : "-",
            companyFebrary: companyFebrary != "" && companyFebrary != undefined ? companyFebrary : "-",
            companyMarch: companyMarch != "" && companyMarch != undefined ? companyMarch : "-",
            companyApril: companyApril != "" && companyApril != undefined ? companyApril : "-",
            companyMay: companyMay != "" && companyMay != undefined ? companyMay : "-",
            companyJune: companyJune != "" && companyJune != undefined ? companyJune : "-",
            companyJuly: companyJuly != "" && companyJuly != undefined ? companyJuly : "-",
            companyAugust: companyAugust != "" && companyAugust != undefined ? companyAugust : "-",
            companySeptember: companySeptember != "" && companySeptember != undefined ? companySeptember : "-",
            companyOctober: companyOctober != "" && companyOctober != undefined ? companyOctober : "-",
            companyNovember: companyNovember != "" && companyNovember != undefined ? companyNovember : "-",
            companyDecember: companyDecember != "" && companyDecember != undefined ? companyDecember : "-"
        }

        const query = await fetch(`${URL_ROUTE.INSERTVERIFYMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async reportsFilters(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-', transfer = '-', verify_backoffice = '-', user_idprofile = '-', validateReNew = '-', management = '-', filterAgent = '-', filterFiles = '-', filterNPN = '-', companyPolicyTypeFilter = '-', filterSuper = '-', locationUrl = '-', filterSignature = '-', filterStatusComplete = '-', immigrationFilter = '-', user_id = '-', user_agents = [], idCardFilter = '-', lifeChangeFilter = '-', documentsFilter = '-', socialFilter = '-') {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: salesman == '' ? '-' : String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery,
            transfer, verify_backoffice, user_idprofile: String(user_idprofile), validateReNew, management, filterAgent, filterFiles, filterNPN, companyPolicyTypeFilter, filterSuper, filterSignature: filterSignature == '' ? '-' : filterSignature, filterStatusComplete: filterStatusComplete != '' ? String(filterStatusComplete) : '-', locationUrl, immigrationFilter: immigrationFilter != '' ? immigrationFilter : '-', user_id: String(user_id), user_agents, idCardFilter, lifeChangeFilter, documentsFilter, socialFilter
        }
        console.log(data, "data reportsFilters");
        const query = await fetch(`${URL_ROUTE.REPORTSFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async reportsFiltersSupervisor(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-', transfer = '-', verify_backoffice = '-', user_idprofile = '-', validateReNew = '-', user_id, filterNPN = '-', locationUrl = '-', documentsFilter = '-', socialFilter = '-') {
        console.log(filterdate, "filterdate api");
        console.log(filterEndDate, "filterEndDate api");
        console.log(filterphone, "filterphone api");
        console.log(filterfullname, "filterfullname api");
        console.log(stateFilter, "stateFilter api");
        console.log(policyTypeFilter, "policyTypeFilter api");
        console.log(filterIdSales, "filterIdSales api");
        console.log(id_status, "id_status api");
        console.log(salesman, "salesman api");
        console.log(idstatus_finish, "idstatus_finish api");
        console.log(typeCallFilter, "typeCallFilter api");
        console.log(recovery, "recovery api");
        console.log(transfer, "transfer api");
        console.log(verify_backoffice, "verify_backoffice api");
        console.log(user_idprofile, "user_idprofile api");
        console.log(validateReNew, "validateReNew api");
        console.log(user_id, "user_id api");
        console.log(user_id, "user_id api");
        console.log(user_id, "user_id api");
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery,
            transfer, verify_backoffice, user_idprofile: String(user_idprofile), validateReNew, user_id, filterNPN, locationUrl, documentsFilter, socialFilter
        }
        const query = await fetch(`${URL_ROUTE.REPORTSFILTERSUPERVISOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async exportDataExcelFilters(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-', transfer = '-', verify_backoffice = '-', user_idprofile = '-', validateReNew = '-', management = '-', filterAgent = '-', filterFiles = '-', filterNPN = '-', companyPolicyTypeFilter = '-', filterSuper = '-', locationUrl = '-', filterSignature = '-', filterStatusComplete = '-', immigrationFilter = '-', user_id = '-', user_agents = [], idCardFilter = '-', lifeChangeFilter = '-') {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery,
            transfer, verify_backoffice, user_idprofile: String(user_idprofile), validateReNew, management, filterAgent, filterFiles, filterNPN, companyPolicyTypeFilter, filterSuper, filterSignature: filterSignature == '' ? '-' : filterSignature, filterStatusComplete: filterStatusComplete != '' ? String(filterStatusComplete) : '-', locationUrl, immigrationFilter: immigrationFilter != '' ? immigrationFilter : '-', user_id: String(user_id), user_agents, idCardFilter, lifeChangeFilter
        }
        const query = await fetch(`${URL_ROUTE.EXPORTDATAEXCELFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query
    }

    async listSalesBackOfficeFilter(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, transfer, validateReNew) {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales, transfer, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.LISTSALESBACKOFFICEFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listHostTransfers() {
        const query = await fetch(`${URL_ROUTE.LISTHOSTTRANSFERS}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async searchsalespreOther(id_status, transfer, validateReNew) {
        const data = {
            id_status, transfer, validateReNew
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPREOTHER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createMemberID(id_sales, member_id) {
        const data = {
            id_sales, member_id
        }
        const query = await fetch(`${URL_ROUTE.CREATEMEMBERID}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async SignatureSherpa(id, username) {
        const data = { id, username }
        const query = await fetch(`${URL_ROUTE.SIGNATURESHERPA}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async exportDataCMS(id_sales) {
        let data = { id_sales }
        const query = await fetch(`${URL_ROUTE.EXPORTDATACMS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async exportDataSale(id_sales) {
        let data = { id_sales }
        const query = await fetch(`${URL_ROUTE.EXPORTDATASALE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async viewDataCMS(id_sales) {
        let data = { id_sales }
        const query = await fetch(`${URL_ROUTE.VIEWDATACMS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async viewLogStatusSales(id_sales) {
        let data = { id_sales }
        const query = await fetch(`${URL_ROUTE.VIEWLOGSTATUSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async checkListQuality(id_sales) {
        let data = { id_sales }
        const query = await fetch(`${URL_ROUTE.CHECKLISTQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveCheckListQuality(id_sales, checklist, iduser, nameuser) {
        let data = { id_sales, checklist, iduser, nameuser }
        const query = await fetch(`${URL_ROUTE.SAVECHECKLISTQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async returnQuality(id, observation, user_id, nameuser) {
        let data = { id, observation, user_id, nameuser }
        const query = await fetch(`${URL_ROUTE.RETURNQUALITY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async TransferSales(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, projectDataBase, project_in, idstatus, transfer, saleRenew) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('uploadfiles', JSON.stringify(uploadfiles))
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('project', projectDataBase)
        data.append('project_in', project_in)
        data.append('idstatus', idstatus)
        data.append('transfer', transfer)
        data.append('saleRenew', saleRenew)
        const query = await fetch(`${URL_ROUTE.TRANSFERSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async listRowSaleMonth() {
        const query = await fetch(`${URL_ROUTE.LISTROWSALEMONTH}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async listRowSaleMonthUser(iduser) {
        let data = { iduser }
        const query = await fetch(`${URL_ROUTE.LISTROWSALEMONTHUSER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createRowSaleMonth(iduser, months) {
        let data = { iduser, months }
        const query = await fetch(`${URL_ROUTE.CREATEROWSALEMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deleteRowSaleMonth(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.DELETEROWSALEMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalesCustomerMonthFilter(id, id_agency, user_id, dateStart, dateEnd, phone, fullName, state, policytype, id_sales, callorigin, transfer, filterAgent = '-', filterFiles = '-', filterNPN = '-', companyPolicyTypeFilter = '-', filterSuper = '-', filterSignature = '-', user_agents, idCardFilter = '-', lifeChangeFilter = '-', locationUrl) {
        let data = {
            dateStart: dateStart == '' || dateStart == null ? '-' : dateStart,
            dateEnd: dateEnd == '' || dateEnd == null ? '-' : dateEnd,
            phone: phone == '' ? '-' : phone,
            fullName: fullName == '' ? '-' : fullName,
            state: state == '' ? '-' : state,
            callorigin: callorigin == '' ? '-' : callorigin,
            policytype: policytype == '' ? '-' : policytype,
            id, id_agency, user_id: String(user_id), id_sales: id_sales == '' ? '-' : id_sales, transfer, filterAgent, filterFiles, filterNPN, companyPolicyTypeFilter, filterSuper, filterSignature: filterSignature == '' ? '-' : filterSignature, user_agents, idCardFilter, lifeChangeFilter, locationUrl
        }
        console.log(data, "data");
        const query = await fetch(`${URL_ROUTE.SEARCHSALESCUSTOMERMONTHFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchNPNForState(id_state) {
        let data = { id_state }
        const query = await fetch(`${URL_ROUTE.SEARCHNPNFORSTATE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async addObservationSale(id, observation) {
        let data = { id, observation }
        const query = await fetch(`${URL_ROUTE.ADDOBSERVATIONSALE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSSNOptions() {
        const query = await fetch(`${URL_ROUTE.TOTALSSNOPTIONS}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(data)
        });
        return query
    }

    async totalProcessingOptions(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-', transfer = '-', verify_backoffice = '-', user_idprofile = '-', validateReNew = '-', management = '-', filterAgent = '-', filterFiles = '-', filterNPN = '-', companyPolicyTypeFilter = '-', filterSuper = '-') {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery,
            transfer, verify_backoffice, user_idprofile: String(user_idprofile), validateReNew, management, filterAgent, filterFiles, filterNPN, companyPolicyTypeFilter, filterSuper
        }
        const query = await fetch(`${URL_ROUTE.TOTALPROCESSINGOPTIONS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalStatusFinish(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-', transfer = '-', verify_backoffice = '-', user_idprofile = '-', validateReNew = '-', management = '-', filterAgent = '-', filterFiles = '-', filterNPN = '-', companyPolicyTypeFilter = '-', filterSuper = '-') {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery,
            transfer, verify_backoffice, user_idprofile: String(user_idprofile), validateReNew, management, filterAgent, filterFiles, filterNPN, companyPolicyTypeFilter, filterSuper
        }
        const query = await fetch(`${URL_ROUTE.TOTALSTATUSFINISH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveSignature(idsale, signature, iduser, nameuser) {
        let data = {
            idsale, signature, iduser, nameuser
        }
        const query = await fetch(`${URL_ROUTE.SAVESIGNATURE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveApplicantSignature(idsale, applicant, nameuser) {
        let data = {
            idsale, applicant, nameuser
        }
        const query = await fetch(`${URL_ROUTE.SAVEAPPLICANTSIGNATURE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async filterConsultor(startDateFilter, endDateFilter, stateFilter, npnFilter, view, company, user_agents) {
        let data = { startDateFilter: startDateFilter != "" ? startDateFilter : '-', endDateFilter: endDateFilter != "" ? endDateFilter : '-', stateFilter: stateFilter != "" ? stateFilter : '-', npnFilter: npnFilter != "" ? npnFilter: '-', view, company, user_agents }
        const query = await fetch(`${URL_ROUTE.FILTERCONSULTOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async addObservationSale(id, observation) {
        let data = { id, observation }
        const query = await fetch(`${URL_ROUTE.ADDOBSERVATIONSALE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async statusComplete(id, idstatus, user_id, username) {
        let data = { id, idstatus, user_id, username }
        const query = await fetch(`${URL_ROUTE.STATUSCOMPLETE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async statusManagement(id, idstatus, user_id, username) {
        let data = { id, idstatus, user_id, username }
        const query = await fetch(`${URL_ROUTE.STATUSMANAGEMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveEmail(idsender, idto, username, subject, message, idemail, typesend, template, attachments, original, idprofile) {
        let data = { idsender, idto, username, subject, message, idemail, typesend, template, attachments, original, idprofile }
        const query = await fetch(`${URL_ROUTE.SAVEEMAIL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listEmail(iduser) {
        let data = { iduser }
        const query = await fetch(`${URL_ROUTE.LISTEMAIL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listFilterEmail(iduser, dateStart, dateEnd, userFilter) {
        let data = { iduser, dateStart, dateEnd, userFilter }
        const query = await fetch(`${URL_ROUTE.LISTFILTEREMAIL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listEmailReply(idemail) {
        let data = { idemail }
        const query = await fetch(`${URL_ROUTE.LISTEMAILREPLY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async emailUnread(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.EMAILUNREAD}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listNotificationLimit(iduser) {
        let data = { iduser }
        const query = await fetch(`${URL_ROUTE.LISTNOTIFICATIONLIMIT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listNotification(iduser) {
        let data = { iduser }
        const query = await fetch(`${URL_ROUTE.LISTNOTIFICATION}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateViewNotification(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.UPDATEVIEWNOTIFICATION}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchPayMonth(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHPAYMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async exportPayMonth(year, month, company, user_agents) {
        let data = { year, month: month != '' ? month:'-', company: company != '' ? company:'-', user_agents}
        const query = await fetch(`${URL_ROUTE.EXPORTPAYMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query
    }

    async searchsalesLifeChange(iduser) {
        let data = {
            iduser
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESLIFECHANGE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveLifeChange(id, life_change, life_change_observation, iduser, username) {
        let data = { id, life_change, life_change_observation, iduser, username }
        const query = await fetch(`${URL_ROUTE.SAVELIFECHANGE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveResponseLifeChange(id, life_change_observation_processor, iduser, username) {
        let data = { id, life_change_observation_processor, iduser, username }
        const query = await fetch(`${URL_ROUTE.SAVERESPONSELIFECHANGE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async sendEmailCMS(id, iduser, username, url, email, original, idprofile) {
        let data = { id, iduser, username, url, email, original, idprofile }
        const query = await fetch(`${URL_ROUTE.SENDEMAILCMS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async deleteNumberUploadAgents(agent,status) {
        let data = { agent,status }
        const query = await fetch(`${URL_ROUTE.DELETENUMBERUPLOADAGENTS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listTotalStatusUploadAgent() {
        //let data = { agent,status }
        const query = await fetch(`${URL_ROUTE.LISTTOTALSTATUSUPLOADAGENT}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            //body: JSON.stringify(data)
        });
        return query
    }

    async exportUploadAgents(agent,status) {
        let data = {agent,status}
        const query = await fetch(`${URL_ROUTE.EXPORTUPLOADAGENTS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query
    }

    async saveStatusComplete(id, idstatus_complete, observation, iduser, username) {
        let data = { id, idstatus_complete, observation, iduser, username }
        const query = await fetch(`${URL_ROUTE.SAVESTATUSCOMPLETE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalCallsAgents(dateStart, dateEnd, agents, typeCalls) {
        let data = {dateStart, dateEnd, agents, typeCalls}
        const query = await fetch(`${URL_ROUTE.TOTALCALLSAGENTS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveHasAgent(id, url_agent, iduser, username) {
        let data = {id, url_agent, iduser, username}
        const query = await fetch(`${URL_ROUTE.SAVEHASAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async saveApprovedAgent(id, approvedAgent, iduser, username, salesman, observationHasAgent) {
        let data = {id, approvedAgent, iduser, username, salesman, observationHasAgent}
        const query = await fetch(`${URL_ROUTE.SAVEAPPROVEDAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listLeadDispositions(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.LISTLEADDISPOSITIONS}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async saveStatusLeadDispositions(id_lead_dispositions, id_status, comment, user_name) {
        let data = { id_lead_dispositions, id_status, comment, user_name }
        const query = await fetch(`${URL_ROUTE.SAVESTATUSLEADDISPOSITIONS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listStatusLeadDispositions(id_lead_dispositions) {
        let data = { id_lead_dispositions }
        const query = await fetch(`${URL_ROUTE.LISTSTATUSLEADDISPOSITIONS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async filterLeadDispositions(id_status, date_start, date_end, iduser) {
        let data = {
            id_status: id_status != "" ? id_status : '-',
            date_start: date_start != "" ? date_start : '-',
            date_end: date_end != "" ? date_end : '-', iduser: String(iduser) }
        const query = await fetch(`${URL_ROUTE.FILTERLEADDISPOSITIONS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async filterTotalLeadDispositions(id_status, date_start, date_end, iduser) {
        let data = {
            id_status: id_status != "" ? id_status : '-',
            date_start: date_start != "" ? date_start : '-',
            date_end: date_end != "" ? date_end : '-', iduser: String(iduser) }
        const query = await fetch(`${URL_ROUTE.FILTERTOTALLEADDISPOSITIONS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async filterTotalStatusLeadDispositions(id_status, date_start, date_end, iduser) {
        let data = {
            id_status: id_status != "" ? id_status : '-',
            date_start: date_start != "" ? date_start : '-',
            date_end: date_end != "" ? date_end : '-', iduser: String(iduser) }
        const query = await fetch(`${URL_ROUTE.FILTERTOTALSTATUSLEADDISPOSITIONS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async exportExcelLead(id_status, date_start, date_end) {
        let data = {
            id_status: id_status != "" ? id_status : '-',
            date_start: date_start != "" ? date_start : '-',
            date_end: date_end != "" ? date_end : '-' }
        const query = await fetch(`${URL_ROUTE.EXPORTEXCELLEADS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async searchTemplateEmail(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHTEMPLATEEMAIL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async uploadFileEmails(upload) {
        let formData = new FormData();
        formData.append('uploadFilesEmails', upload);

        const query = await fetch(`${URL_ROUTE.UPLOADFILEEMAILS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async uploadFileLeads(upload, id_status, user_name, iduser) {
        let formData = new FormData();
        formData.append('upload', upload);
        formData.append('id_status', id_status);
        formData.append('user_name', user_name);
        formData.append('iduser', iduser);

        const query = await fetch(`${URL_ROUTE.UPLOADFILELEADS}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async saveCardSent(id, card_is_sent, iduser, username) {
        let data = { id, card_is_sent, iduser, username }
        const query = await fetch(`${URL_ROUTE.SAVECARDSENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async getTotalSalesHasAgent() {
        const query = await fetch(`${URL_ROUTE.GETTOTALSALESHASAGENT}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async statusIndicatorsSales(year, month, npn, company, idstatus_finish) {
        let data = { year, month, npn, company, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.STATUSINDICATORSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async metalIndicatorsSales(year, month, npn, company, idstatus_finish) {
        let data = { year, month, npn, company, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.METALINDICATORSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async companysIndicatorsSales(year, month, npn, company, idstatus_finish) {
        let data = { year, month, npn, company, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.COMPANYSINDICATORSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async ssnIndicatorsSales(year, month, npn, company, idstatus_finish) {
        let data = { year, month, npn, company, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.SSNINDICATORSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async completeAndTotalIndicatorsSales(year, month, npn, company, idstatus_finish) {
        let data = { year, month, npn, company, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.COMPLETEANDTOTALINDICATORSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async ssnNPNIndicatorsSales(year, month, npn, company, idstatus_finish) {
        let data = { year, month, npn, company, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.SSNNPNINDICATORSSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
}

export default new Api();